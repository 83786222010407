import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import { BrowserRouter as Router, Routes, Route, Link ,useLocation} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Footer from '../components/Footer';


function Faq() {

    const [email, setEmail] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted with email:', email);
    };

    const images = [
        './dummy-image-square.jpg',
        './dummy-image-square.jpg',
        './dummy-image-square.jpg',
        './dummy-image-square.jpg',
    ];
    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#product') {
            const element = document.getElementById('product');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <div className="Home">
            <div className='position-relative headerImage'>
                <img src="./Texture.svg" className="position-absolute top-0 start-0 header-lines" />
                <Navbar collapseOnSelect expand="lg" style={{ background: "rgb(47, 62, 57)" }} className="sticky-top">
                    <Container>
                        <Navbar.Brand href="#home">
                            <a className="navbar-brand mx-auto" href="#">
                                <img src="./Group.svg" alt="Brand Logo" />
                            </a>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">

                            </Nav>
                            <Nav>
                                <Nav.Link as={Link} to="/">Home</Nav.Link>
                                <Nav.Link as={Link} to="/#aboutus">About Us</Nav.Link>
                                <Nav.Link as={Link} to="/#project">Project</Nav.Link>
                                <li className="nav-item">
                                    <Nav.Link as={Link} to="/faq">
                                        FAQ
                                    </Nav.Link>
                                </li>
                                <Nav.Link as={Link} to="/#contactus">Contact Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 mt-4'>
                            <div className='text-center'>
                                <h5>FAQs</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div>
                                <h6>1. Are There Any Affordable Residential Plots For Investment In Telangana?</h6>
                                <p>Yes, Ruthra Developers’ Madhura Nagar project offers affordable residential plots for investment offers plots at lower prices in an area that has excellent infrastructure and connectivity to the city.</p>
                            </div>
                            <div className='mt-4'>
                                <h6>2. Which area is best for residential plot investment in Hyderabad?</h6>
                                <p>Madhura Nagar near Kalwakurthy is a promising area for property investment due to its strategic location near the Hyderabad-Tirupati express highway and rapidly developing infrastructure.</p>
                            </div>
                            <div className='mt-4'>
                                <h6>3. What Are The Advantages Of Buying DTCP Approved Layout Plots?</h6>
                                <p>DTCP Approved Layout Plots are legally compliant and follow the required standards and specifications approved by the authorities. This helps protect your investment and protects your property from legal compliance issues.</p>
                            </div>
                            <div className='mt-4'>
                                <h6>4. What Is The Difference Between Agricultural Plots And Residential Plots?</h6>
                                <p>The primary difference is that agricultural plots are meant for farming and agricultural purposes whereas residential plots are used for constructing homes.</p>
                            </div>
                            <div className='mt-4'>
                                <h6>5. Are There Any Tax Benefits Of Purchasing Residential Plots In Hyderabad?</h6>
                                <p>Depending on your financial situation, purchasing residential plots in Hyderabad may offer tax benefits such as deductions on home loan interest and property taxes.</p>
                            </div>
                            <div className='mt-4'>
                                <h6>6. Is Buying Residential Plots Outside Hyderabad A Good Investment Opportunity?</h6>
                                <p>With rapid development in and around the city, purchasing plots outside Hyderabad is an ideal investment opportunity. Investing in Madhura Nagar near Kalwakurthy offers lower prices, promising returns, and future development prospects.</p>
                            </div>
                            <div className='mt-4'>
                                <h6>7. Can You Construct A Residential Property On Telangana Farmland?</h6>
                                <p>No. Agricultural Land in Telangana can only be utilized for farming and agricultural purposes, while residential properties can only be built on plots that are specifically designated for residential use.</p>
                            </div>
                            <div className='mt-4'>
                                <h6>8. Is Buying Agricultural Land In Hyderabad, Telangana Profitable?</h6>
                                <p>Agricultural Land in Hyderabad, Telangana may be profitable for farming or agricultural purposes. However, it is not suitable for residential construction without land conversion and authorized permissions.</p>
                            </div>
                            <div className='mt-4'>
                                <h6>9. Can We Buy Residential Plots In Installments Or EMI In Hyderabad Telangana?</h6>
                                <p>Yes, our Scheme Plan makes it easier for you to purchase our Open Plots In Kalwakurthy with easy installments. Contact our team for more information on purchasing your property in easy installments.</p>
                            </div>
                            <div className='mt-4'>
                                <h6>10. Are There Any New Agriculture Farmland Plot Ventures in Hyderabad?</h6>
                                <p>Yes, there are quite a few agricultural farmland plot ventures in Hyderabad, but Ruthra Developers is currently offering residential plots only.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div>
                        <Footer />
                    </div>
                </div>  
            </div>


        </div>
    );
}

export default Faq;
