import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <div className="footer pt-4">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    {/* Social Media Icons and Brochure Download */}
                    <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                        <div className="d-flex">
                            <a
                                className="text-decoration-none d-flex align-items-center justify-content-center rounded-circle"
                                href="#"
                                style={{ width: '40px', height: '40px', backgroundColor: '#000' }}
                            >
                                <FaFacebookF color="#fff" size={20} />
                            </a>

                            <a
                                className="text-decoration-none d-flex align-items-center justify-content-center rounded-circle mx-2"
                                href="#"
                                style={{ width: '40px', height: '40px', backgroundColor: '#000' }}
                            >
                                <FaTwitter color="#fff" size={20} />
                            </a>

                            <a
                                className="text-decoration-none d-flex align-items-center justify-content-center rounded-circle"
                                href="#"
                                style={{ width: '40px', height: '40px', backgroundColor: '#000' }}
                            >
                                <FaInstagram color="#fff" size={20} />
                            </a>

                            <div className="ms-3">
                                <a
                                    href="/ruthra_developers_madhuranagar_brochure.pdf"
                                    download
                                    className="btn btn-primary h-100 d-flex align-items-center"
                                >
                                    Download Brochure
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Powered By Section */}
                    <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end align-items-center text-center">
                        <div className="me-3">
                            <small>Powered By</small><br />
                            <a href="https://sparktics.com/" target="_blank" className="h4 fw-bold text-decoration-none">
                                SPARKTICS
                            </a>
                        </div>
                    </div>

                    {/* Copyright Section */}
                    <div className="col-12 text-center pt-3">
                        <small>© 2000-2024, All rights reserved</small>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
