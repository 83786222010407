import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';



function Project() {

    const [email, setEmail] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted with email:', email);
    };


    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#product') {
            const element = document.getElementById('product');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const images = [
        './dummy-image-square.jpg',
        './dummy-image-square.jpg',
        './dummy-image-square.jpg',
        './dummy-image-square.jpg',
       
        
    ];

    return (
        <div className="Home">


            <div class='position-relative headerImage'>

                <img src="./Texture.svg" class="position-absolute top-0 start-0 header-lines" />
                <nav class="navbar navbar-dark navbar-expand-md rd-green sticky-top" id="navbar">
                    <div class="container text-center">
                        <a class="navbar-brand mx-auto" href="#">
                            <img src="./Group.svg" />
                        </a>
                        <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                            <ul class="navbar-nav ms-auto d-flex">
                                <li class="nav-item">
                                    <a class="nav-link active" aria-current="page" href="/">Home</a>
                                </li>
                                
                                <li class="nav-item">
                                    
                                    <a className="nav-link" href="/#product">Properties</a>

                                </li>
                                <li className="nav-item text-decoration-none">
                                    <Link to="/faq" >
                                        <a className="nav-link">FAQ</a>
                                    </Link>
                                </li>
                                <li class="nav-item">
                                    <a className="nav-link" onClick={toggleDrawer(true)} href="javascript:void(0)">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                   
        </div>
        </nav > 

                <div class="container">
                    <img class="w-100 py-5" src="./Group432.svg"/>
                </div>

                <div class="container">
                    <div class="row wyChooseUs2 gx-5">
                        <div class="col-3">
                            <img src="./amenties.svg" class="w-100" />
                        </div>
                        <div class="col-3">
                            <img src="./lifeStyle.svg" class="w-100" />
                        </div>
                        <div class="col-3">
                            <img src="./community.svg" class="w-100" />
                        </div>
                        <div class="col-3">
                            <img src="./value.svg" class="w-100" />
                        </div>
                    </div>
                </div>

                
                <div class="container pt-5 overflow-hidden">
                    <div class="main-heading display-6 pb-3">
                        Project Name
                        <span></span>
                    </div>
                    <p class="projectText">
                        Find your dream home easily using our property search tool. With an intuitive interface and advanced
                        filters, you can narrow down properties based on location,
                    </p>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="sub-heading h3">Key Features</div>
                            <ul>
                            </ul>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="sub-heading h3">Deatiled Features</div>
                            <ul>
                            </ul>
                        </div>
                        <div class="col-12 text-center pt-5">
                            <button class="btn rounded-0 btn-lg px-4" style={{ "background": "#B1A28D" }}>
                                <img src="./plotmap.svg" style={{ "width": "100px "}} />
                            </button>
                        </div>
                    </div>
                </div>

                
                <div class="container py-5 overflow-hidden">
                    <div class="main-heading display-6 pb-3">
                        Area Map
                        <span></span>
                    </div>
                    {/* <div class="d-flex justify-content-end">
                        <select class="form-control text-uppercase" style={{"maxWidth": "240px","background": "transparent"}} v-model="selecytedMap">
                            <option v-for="map in Object.keys(product.maps || {})" class="text-uppercase">{{ map }}</option>
                        </select>
                    </div> */}
                    <div class="p-4" v-if="product.maps">
                        <img src="./map.svg" class="w-100" />
                    </div>

                </div>

                
                <div class="container py-5 overflow-hidden">
                    <div class="main-heading display-6">
                        Image Gallery
                        <span></span>
                    </div>
                    {/* <div class="gallerySlink py-4">
                        <div v-for="image in product.gallery || []" class="p-3 rounded">
                            <img:src="image" class="w-100">
                        </div>
                    </div> */}

                    <div className="gallery py-4">
                        {images.map((image, index) => (
                            <div key={index} className="gallery-item p-3 rounded">
                                <img src={image} alt={`Gallery item ${index}`} />
                            </div>
                        ))}
                    </div>

                </div>


              

               

              

               
            </div>



            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} sx={{ '& .MuiDrawer-paper': { width: '500px' } }}>
                <div class="p-4">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="sub-heading h3">
                            Take the next step towards finding your perfect plot or property
                            <p>Fill out the details below and our team will get in touch with you shortly.</p>
                        </div>
                    </div>
                    <div class="mb-3">
                        <img src="./124010.png" style={{ "width": "30px", "height": "30px" }} class="rounded" />
                        <img src="./Instagram_icon.png.webp" style={{ "width": "30px", "height": "30px" }} class="rounded mx-2" />
                    </div>
                    <div class="card p-3 mx-3 shadow border-0">
                        <div class="card-body">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="fname" v-model="firstName" placeholder="First Name" />
                                <label for="fname">* First Name</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="lName" v-model="lastName" placeholder="Last Name" />
                                <label for="lName">* Last Name</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="email" v-model="email" placeholder="Email Address" />
                                <label for="email">* Email address</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="phone" v-model="phone" placeholder="Phone" />
                                <label for="phone">* Phone</label>
                            </div>
                            <div class="form-floating mb-3">
                                <textarea class="form-control" placeholder="Leave a comment here" id="message" v-model="message"></textarea>
                                <label for="message">
                                    Message<br />
                                </label>
                            </div>
                            <div class="mb-3 text-center">
                                <button class="contactSubmit btn w-75 text-white btn-lg">
                                    Send Details
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="main-heading display-6 py-5">
                        View on Map
                    </div>
                    <div class="w-100">
                        <iframe
                            style={{ "height": "100 %" }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d487295.22889382177!2d78.07768322686312!3d17.412076752377896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1714593097726!5m2!1sen!2sin"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </Drawer>

        </div>
    );
}

export default Project;
