import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Project from './components/Project';
import Faq from './components/Faq';

function App() {
  useEffect(() => {
    const currentUrl = window.location.href;

    // Redirect if the URL is missing "www" or using "http"
    if (currentUrl.startsWith("http://ruthradevelopers.com")) {
      window.location.href = "https://www.ruthradevelopers.com";
    } else if (currentUrl.startsWith("https://ruthradevelopers.com")) {
      window.location.href = "https://www.ruthradevelopers.com";
    }
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/project" element={<Project />} />
          <Route path="/faq" element={<Faq />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
