import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Footer from '../components/Footer';
import 'react-awesome-slider/dist/styles.css';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { supabase } from './supabaseClient'; // Import the Supabase client

function Home() {
    const [email, setEmail] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
   
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { firstName, lastName, email, phoneNumber, message } = formData;

        const { data, error } = await supabase
            .from('contacts')
            .insert([
                {
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    phone_number: phoneNumber,
                    message
                }
            ]);

        if (error) {
            alert('Error submitting form');
            console.error(error);
        } else {
            alert('Form submitted successfully');
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                message: '',
            });
        }
    };
    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

   

    const location = useLocation();
    const AutoplaySlider = withAutoplay(AwesomeSlider);

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1)); // Remove the '#' from hash
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <div className="Home">
            <div className="position-relative headerImage">
                <img src="./Texture.svg" className="position-absolute top-0 start-0 header-lines" />
                <Navbar collapseOnSelect expand="lg" style={{ background: "rgb(47, 62, 57)" }} className="sticky-top">
                    <Container>
                        <Navbar.Brand href="#home">
                            <a className="navbar-brand mx-auto" href="#">
                                <img src="./Group.svg" alt="Brand Logo" />
                            </a>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto"></Nav>
                            <Nav>
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="#aboutus">About Us</Nav.Link>
                                <Nav.Link href="#project">Project</Nav.Link>
                                <li className="nav-item">
                                    <Nav.Link as={Link} to="/faq">
                                        FAQ
                                    </Nav.Link>
                                </li>
                                <Nav.Link href="#contactus">Contact Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Container fluid style={{ padding: 0 }}>
                    <AutoplaySlider
                        style={{ width: '100%', height: '70vh' }} // Set slider height to 70% of the viewport height
                        bullets={true} // Optional: Show bullets
                        play={true} // Enable autoplay
                        cancelOnInteraction={false} // Keep autoplay even after user interaction
                    interval={5000} // Set interval for 3 seconds (3000 ms)
                    >
                        <div data-src="/slider1.jpeg">
                            <div className="overlay-content">
                                <h2>Redefining The Real Estate Landscape</h2>
                                <p className='text-white '>Experience a new standard in real estate with perfectly < br /> planned and well-connected DTCP & RERA approved residential plots in Kalwakurthy</p>
                                <a className="rd-discover px-3 py-2 my-4 btn btn-danger rounded-0" href="#explore">
                                    Explore
                                </a>
                            </div>
                        </div>
                        <div data-src="/slider2.jpeg">
                            <div className="overlay-content">
                                <h2>Take The First Step Towards Your Dream Home</h2>
                                <p className='text-white'>purchase dtcp approved residential plots in kalwakurthy</p>
                                <a className="rd-discover px-3 py-2 my-4 btn btn-danger rounded-0" href="#explore">
                                    Explore
                                </a>
                            </div>
                        </div>
                        <div data-src="/slider3.jpeg">
                            <div className="overlay-content">
                                <h2>Purchase Your Dream Property with Confidence</h2>
                                <p className='text-white'>Explore affordable plots for land investment in hyderabad in our regulatory-compliant plots.</p>
                                <a className="rd-discover px-3 py-2 my-4 btn btn-danger rounded-0" href="#explore">
                                    Explore
                                </a>
                            </div>
                        </div>
                    </AutoplaySlider>
                </Container>

                <div id="aboutus" className="container py-5 overflow-hidden">
                    <div className="main-heading display-6">
                        About Us
                        <span></span>
                    </div>

                    <div className="row align-items-center whyChooseUs py-3">


                        <div class="col-12 ">
                            <p style={{ "lineHeight": "35px", "textAlign": "justify" }} >
                                Ruthra Developers was formed by a group of young entrepreneurs who came together to offer innovative and high-quality solutions in the real estate industry. It is a leading real estate company offering residential plots for sale in Madhura Nagar in Kalwakurthy. Our plots fall on the 40 feet road from Kalwakurthy to Kurummida with a wide frontage of 260 ft. These ready-to-build open plots have all the necessary infrastructure and amenities making it easier for property buyers to start constructing their homes immediately. All plots adhere to Vastu principles promising a harmonious living environment for your future home. The surrounding amenities make our Madhura Nagar project a practical and luxurious choice for home construction. These amenities include schools, offices, hospitals, theatres, religious places, supermarkets, fuel stations, shopping centres and more. We are renowned for offering DTCP and RERA approved affordable farm lands in Kalwakurthy, Telangana.
                            </p>
                        </div>
                    </div>


                </div>

                <div className="container py-3 overflow-hidden">
                    <div className="main-heading display-6">
                        Why Choose Us
                        <span></span>
                    </div>

                    <div className="row align-items-center whyChooseUs py-3">
                        <div className="col-9 col-md-5 title h2">
                            Your partners <br /> in properties..
                        </div>
                        <div className="col-3 col-md-2 text-center py-3">
                            <img
                                src="/favicon.png"
                                className="w-100"
                                alt=""
                            />
                        </div>
                        <div className="col-12 col-md-5">
                            <p>
                                We make building your dream home a reality by offering premium, affordable plots for sale in Nagarkurnool District.
                                Our plots are fully equipped with essential infrastructure and facilities to help you start constructing your
                                home immediately after purchase.
                            </p>
                        </div>
                    </div>
                    <div className="row wyChooseUs2 gx-5">
                        <div className="col-12 col-md-4 mb-3">
                            <div className="border h-100 pb-2">
                                <div className="iconsBackground">
                                    <img src="./Untitled.svg" className="w-50" alt="Affordable Plots" />
                                </div>
                                Affordable Plots in Prime Locations <br />
                                <h6 className="text-justify" style={{ lineHeight: '27px', padding: '10px 10px' }}>
                                    We offer a wide range of plots at affordable prices at one of the top locations for land investment in
                                    Hyderabad, making property ownership a reality for everyone.
                                </h6>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <div className="border h-100 pb-2">
                                <div className="iconsBackground">
                                    <img src="./nhkR4I.tif_1_.svg" className="w-50" alt="DTCP & RERA Approved" />
                                </div>
                                DTCP & RERA Approved <br />
                                <h6 className="text-justify" style={{ lineHeight: '27px', padding: '10px 10px' }}>
                                    With certified approvals, we offer DTCP approved residential plots in Kalwakurthy with clear titles meeting
                                    regulatory standards ensuring your plot purchase is hassle-free.
                                </h6>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <div className="border h-100 pb-2">
                                <div className="iconsBackground">
                                    <img src="./budjet.svg" className="w-50" alt="DTCP & RERA Approved" />
                                </div>
                                Incredible Infrastructure <br />
                                <h6 className="text-justify" style={{ lineHeight: '27px', padding: '10px 10px' }}>
                                    Every plot is equipped with essential amenities, including electricity, underground drainage, rainwater
                                    harvesting pits, and other comfortable living facilities.
                                </h6>
                            </div>
                        </div>

                    </div>
                </div>


                <div id="explore" class="container py-5 overflow-hidden">
                    <div class="main-heading display-6">
                        Best Real Estate Developers In Kalwakurthy <span></span>

                    </div>
                    <p>Purchase your dream property with confidence</p>

                    <div class="card collection mt-5" v-for="item in collections">
                        <div class="row g-0 ">


                            <div class="col-md-9 col-12">
                                <div class="container mt-4 d-flex align-items-start">

                                    <img src="./water_management.png" alt="Water Management Image" class="img-fluid me-3" style={{ "width": "70px" }} />


                                    <div>
                                        <h6 style={{ "fontWeight": "bold", "fontSize": "23px" }}>Water Management</h6>
                                        <ul>
                                            <li>Rain Water Harvesting pits for ground recharge.</li>
                                            <li>Stormwater saucer drains to guide rainwater to harvest pits.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="container mt-4 d-flex align-items-start">

                                    <img src="./layoutss.png" alt="Water Management Image" class="img-fluid me-3" style={{ "width": "70px" }} />


                                    <div>
                                        <h6 style={{ "fontWeight": "bold", "fontSize": "23px" }}>Layout Details    </h6>
                                        <ul>
                                            <li>Ac  6-27.5 Gts DTCP & RERA Approved Layout, Clear Title & Vaastu Compliant.</li>
                                            <li>Right on 40 feet road from Kalwakurthy to Kurummida.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="container mt-4 d-flex align-items-start">

                                    <img src="./power.png" alt="Water Management Image" class="img-fluid me-3" style={{ "width": "70px" }} />


                                    <div>
                                        <h6 style={{ "fontWeight": "bold", "fontSize": "23px" }}>Power</h6>
                                        <ul>
                                            <li>Electricity to every plot with street lights throughout the entire layout.</li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="container mt-4 d-flex align-items-start">
                                    <img src="./drainage.png" alt="Water Management Image" class="img-fluid me-3" style={{ "width": "70px" }} />
                                    <div>
                                        <h6 style={{ "fontWeight": "bold", "fontSize": "23px" }}>Drainage</h6>
                                        <ul>
                                            <li>Underground drainage with separate lines on both sides of the road.</li>

                                        </ul>
                                    </div>
                                </div>


                                <div class="container mt-4 d-flex align-items-start">

                                    <img src="./roads.png" alt="Water Management Image" class="img-fluid me-3" style={{ "width": "70px" }} />
                                    <div>
                                        <h6 style={{ "fontWeight": "bold", "fontSize": "23px" }}>Roads</h6>
                                        <ul>
                                            <li>33 Feet wide Black Topped roads of engineering standards with Kerb Stoning for Footpaths.
                                                Comfort living facilities</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="container mt-4 d-flex align-items-start">

                                    <img src="./comfort.png" alt="Water Management Image" class="img-fluid me-3" style={{ "width": "70px" }} />
                                    <div>
                                        <h6 style={{ "fontWeight": "bold", "fontSize": "23px" }}>Comfort living facilities</h6>
                                        <ul>
                                            <li>Central Parks are designed by prominent landscape architects with carpet lawns.</li>
                                            <li>Avenue Plantation is done along the roads to maintain the serenity of the layout.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div id="project" class="container py-5 overflow-hidden">
                    <div></div>
                    <div></div>
                    <div class="main-heading display-6">
                    DTCP Layout - RERA Approved Plots
                    <span></span>
                    </div>

                    <p className='mt-2'>Proposed Residential Layout in Sy. Nos. 104, 105, 106, 126 Situated At Kalwakurthy Kurumidda Road, Nagarkurnool district
                    </p>

                    <div class="container text-center">
                        <div class="row">
                            <div class="col-12 col-md-6 py-3">
                                <Zoom>
                                    <img class="w-100" src="./Phase 1-01.png" alt="Layout 1" />

                                </Zoom>
                            </div>
                            <div class="col-12 col-md-6 py-3">
                                <Zoom>
                                    <img class="w-100" src="./Phase_2.png" alt="Layout 2" />
                                </Zoom>
                            </div>


                        </div>
                    </div>
                </div>

                <div id="contactus" class="container py-3 overflow-hidden">
                    <div class="main-heading display-6">
                        Contact Us
                        <span></span>
                    </div>

                    <div class="row align-items-center whyChooseUs py-3">
                        <div class="col-9 col-md-12 title h2 text-center">
                            Take the next step towards finding your perfect plot or property
                        </div>
                        <div class="col-3 col-md-2 text-center py-3">
                        </div>

                    </div>


                    <div className="row py-3">
                        <div className="col-12 col-md-6">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="firstName" className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        className="form-control"
                                        id="firstName"
                                        placeholder="Enter your first name"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                        />
                                </div>
                                <div className="mb-3">
                                        <label htmlFor="lastName" className="form-label">Last Name</label>
                                        <input
                                        type="text"
                                        name="lastName"
                                        className="form-control"
                                        id="lastName"
                                        placeholder="Enter your last name"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                        />
                                </div>
                                <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email Address</label>
                                        <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        placeholder="Enter your email address"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        />
                                </div>
                                <div className="mb-3">
                                        <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                        <input
                                        type="tel"
                                        className="form-control"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        placeholder="Enter your phone number"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        required
                                        />
                                </div>
                                {/* <div className="mb-3">
                                        <label htmlFor="address" className="form-label">Address</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        name="address"
                                        id="address"
                                        rows="5"
                                        placeholder="Enter your address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        required
                                        />
                                </div> */}
                                <div className="mb-3">
                                        <label htmlFor="message" className="form-label">Message</label>
                                        <textarea
                                        className="form-control"
                                        name="message"
                                        id="message"
                                        rows="5"
                                        placeholder="Enter your message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        />
                                </div>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </div>



                        <div class="col-12 col-md-6">
                            <div class="map-container">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15288.911710620432!2d78.4664794!3d16.6654739!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bca439439271591%3A0x5d32e9725dc363a7!2sMadhura%20Nagar%20by%20Ruthra%20Developers!5e0!3m2!1sen!2sin!4v1725428622254!5m2!1sen!2sin"
                                    width="100%"
                                    height="500"
                                    style={{ border: 0 }}
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
